@use './variables';
@use './mixins.scss';
// @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('./Fonts/Roboto/stylesheet.css');
@import url('./Fonts/styles.scss');
@import url('./Form/styles.scss');
@import url('./Icons/styles.scss');
@import url('./Modals/styles.scss');
@import url('./Table/styles.scss');
@import url('./Utils.scss');

* {
    color: variables.$primary-neutral-text-color;
}

a {
    color: variables.$primary-color;
}

/* Change the color to white for hovered active state */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px variables.$text-color-light inset !important;
    -webkit-text-fill-color: variables.$secondary-border-color;
}

input:hover,
input:focus {
    color: variables.$primary-neutral-text-color !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
    -webkit-text-fill-color: variables.$primary-neutral-text-color;
}

input {
    color: variables.$secondary-neutral-color !important;
}

@media screen and (min-width: 1601px) {

    body,
    * {
        font-size: 16px;
    }
}


@media screen and (min-width: 1367px) and (max-width: 1600px) {

    body,
    * {
        font-size: 16px;
    }

}

@media screen and (max-width: 992px) and (max-width: 1365px) {

    body,
    * {
        font-size: 12px;
    }
}

// Heading Fonts

h1 {
    font-size: 1.25rem;
    margin: 0px;
}

.container-fluid {
    padding: 0 2rem;
}

img {
    max-width: 100%;
}

.bg-primary {
    background: variables.$primary-color;
}



.float-right {
    float: right;
}

.float-left {
    float: left;
}

.float-none {
    float: none;
}

.red {
    color: variables.$primary-danger;

    * {
        color: variables.$primary-danger;
    }
}

.green {
    color: variables.$primary-success;

    * {
        color: variables.$primary-success;
    }
}

.success-circle {

    background-color: variables.$primary-success;

}

.text-faded {
    color: variables.$secondary-neutral-color;
}

.plain-text {
    border: 0px;
    background: none;
}

.no-btn {
    border: 0px;
    background: transparent;
}

.cpointer {
    cursor: pointer;

    * {
        cursor: pointer;
    }
}

.cnotallowed {
    cursor: not-allowed;
}

// BGs
.white-bg {
    background: variables.$text-color-light;
}

// Text Alignmenets

strong,
th {
    font-weight: 500;
}

.text-right {
    text-align: right;
}

.text-strong {
    font-weight: 500;
}

.text-extra-strong {
    font-weight: 600;
}

.text-bold-strong {
    font-weight: 700;
}

.label-sm {
    font-size: 0.875rem;
}

.text-primary {
    color: variables.$primary-color !important;
}

.text-success {
    color: variables.$primary-success !important;
}

.text-danger {
    color: variables.$primary-danger !important;
}

.text-disabled {
    color: variables.$secondary-border-color;

    * {
        color: variables.$secondary-border-color;
    }
}

.link-text {
    text-decoration: underline;
    color: variables.$primary-color;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

// Custom Alert 
.alert-success {
    background: rgba(208, 251, 223, 1);
    border: 1px solid rgba(variables.$primary-success, 0.5);
    color: variables.$primary-success;
}

.alert-dangerr {
    background: rgba(251, 208, 208, 1);
    border: 1px solid rgba(variables.$primary-danger, 0.5);
    color: rgba(variables.$primary-danger, 0.5);
}

.card {
    // border-radius: 0px !important;
    // height: 100% !important;
    // border-radius: 0px !important;

    .card-header {
        height: 50px;
        background: #E2E2E2;
        line-height: 1;
        border-radius: 0px !important;
        display: flex;
        align-items: center;

        h4 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 1rem;
            line-height: 1;
            width: 100%;
            margin: 0px;
        }

        img {
            height: 30px;
            width: auto;
        }
    }

    .card-body {
        background-color: variables.$background-color;
        color: variables.$text-color;
        padding: variables.$block-padding;

        &__btns {
            @include mixins.vertical-list;

            button {
                width: variables.$btn-width;
                height: variables.$btn-height;
            }
        }
    }
}

// Login Block
.login-page {
    display: flex;
    height: calc(100vh - 100px);
    flex: 1;
    justify-content: space-evenly;
}

.logo {
    padding: 50px 0;

    img {
        margin-bottom: 2.5rem;
    }

    p {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
}

p {
    line-height: 1.5;
}


.login-block {
    background: variables.$text-color-light;
    color: variables.$primary-neutral-text-color;
    padding: 2rem;
    border-radius: 0.625rem;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
    min-height: 50vh;
    // min-height: 45vh;
    // height: auto;


    h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    h1 {
        margin-bottom: 1rem;
    }

    p {
        font-size: 0.875rem;
        line-height: 1.25rem;
        color: variables.$secondary-neutral-color;
        margin-bottom: 1.5rem;
    }

    .login-back-link {
        color: variables.$primary-color;
        font-weight: 500;
        margin: 20px 0 0;
        font-size: 0.875rem;

        label {
            cursor: pointer;
            font-size: 0.875rem;
            color: variables.$primary-color;
            font-weight: 500;

            &:hover {
                color: variables.$primary-hover-color;
            }
        }
    }
}

.main-content {
    flex-grow: 1;
    min-height: 80vh;
    padding-top: 1rem;
}

.inner-block {
    background: variables.$text-color-light;
    border-radius: 0.625rem;
}

.quantityEditor {
    width: 10rem;
    display: flex;
    align-items: center;
}

.quantity-col {
    width: 100px;
}

// Custom Dropdown



.accordion-button[aria-expanded=true] {
    background: variables.$button-disabled-background !important;
}

// Accordian Panel
.accordion-panel {
    margin-top: 1rem;

    .accordion {
        border: 0px;
        margin-top: 1rem;

        .accordion-item {
            border: 0px;
            border-radius: 0.5rem;
            overflow: hidden;



            &.accordionErrors {
                border-left: 0.2rem solid variables.$primary-danger;
                border-radius: 1rem;
            }

            .accordion-header {
                background: transparent;

                .accordion-button {
                    color: variables.$primary-neutral-text-color;
                    font-weight: 500;

                    // &:after {
                    //     background-image: url("../Images/Icons/chevronDownLight.svg") !important;
                    //     transform: rotate(deg(180)) !important;
                    // }

                    // &:not(.collapsed)::after {
                    //     background-image: url("../Images/Icons/chevronDownLight.svg") !important;
                    //     transform: rotate(deg(0)) !important;
                    // }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }

                    background: variables.$text-color-light !important;

                    &[aria-expanded=true] {
                        background: variables.$text-color-light !important;
                        box-shadow: none;
                    }
                }

            }
        }

        .disabled {
            h3 {
                label {
                    &:first-child {
                        span {
                            border-color: variables.$secondary-border-color;
                        }
                    }

                    span {
                        color: variables.$secondary-border-color;
                    }
                }
            }

            .accordion-button::after {
                transition: none !important;
                transform: none !important;
            }
        }


    }
}


// Social Media Icons
.social-links {
    padding: 0rem 0;

    >label {
        margin: 0px 0 5px 5px;
        display: inline-block;
        font-size: 0.9rem;
    }

    .social-icons-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .social-icon {
            margin: 0 10px 5px 0;
            display: inline-block;
            width: 2rem;
            height: 2rem;
            overflow: hidden;
            cursor: pointer;

            img {
                width: 1.7rem;
                height: 1.7rem;
            }

            &:hover {
                opacity: 0.8;
            }
        }

        .facebook {
            background: url('../Images/Illustrations/facebook.png') no-repeat center;
            background-size: 2rem;
        }

        .twitter {
            background: url('../Images/Illustrations/twitter.png') no-repeat center;
            background-size: 2rem;
        }

        .linkedin {
            background: url('../Images/Illustrations/linkedin.png') no-repeat center;
            background-size: 2rem;
        }
    }
}

.main-page {
    min-height: 140vh;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
}

.notification {
    position: fixed;
    top: 20px;
    width: 100%;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.circle-bg {
    background: variables.$table-row-hover;
    color: variables.$primary-neutral-text-color;
    min-width: 1.5rem;
    min-height: 1.5rem;
    border-radius: 1.5rem;
    display: inline-block;
    text-align: center;
    padding: 5px;
    line-height: 1rem;
    margin-left: 0.5rem;
}

.successIcon {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    // border: 0.625rem solid rgba(208, 251, 223, 0.5);
    margin: auto;
    // background-image: radial-gradient(rgba(208, 251, 223, 1), rgba(208, 251, 223, 0.5), rgba(208, 251, 223, 1));
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
    display: flex;

    img {
        max-width: 200px;
        margin: auto;
    }
}

.failureIcon {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    // border: 0.625rem solid rgba(208, 251, 223, 0.5);
    margin: auto;
    // background-image: radial-gradient(rgba(208, 251, 223, 1), rgba(208, 251, 223, 0.5), rgba(208, 251, 223, 1));
    background-size: 400% 400%;
    animation: gradient 3s ease infinite;
    display: flex;

    img {
        max-width: 200px;
        margin: auto;
    }
}

.mobile-login-btns {
    // display: none;
}

.dropdown-disabled {
    background-color: variables.$dropdown-disabled !important;
}

// Services Dropdown
.servcies-dropdown {
    background: url('../Images/Icons/chevrondown.svg') no-repeat calc(100% - 1rem) center variables.$table-row-hover;
    width: 100%;
    border-radius: 3.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    &.disabled {
        background-color: variables.$primary-border-color;
    }

    &.nav-item {
        .dropdown-toggle::after {
            background: none !important;
        }
    }

    * {
        font-size: 0.875rem;
    }

    &.show {
        border-radius: 0.75rem 0.75rem 0 0;
    }

    >a {

        padding: 0.6rem 1rem;
        width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        background: variables.$text-color-light;
        z-index: 9999;
        min-width: 100%;
        width: 100%;
        border: 1px solid variables.$primary-border-color;
        border-top: 0px;
        border-radius: 0 0 0.75rem 0.75rem;
        overflow: hidden;
        transform: translate(0px, 2rem) !important;
        padding: 0px;
        max-height: 60vh;
        overflow-y: auto;

        .form-control {
            border-radius: 0;
            border-left: 0;
            border-right: 0;

            &:focus {
                border-top: 0px;
            }

        }

        .dropdown-item {
            background: variables.$text-color-light;
            border-bottom: 1px solid variables.$primary-border-color;
            display: flex;
            color: variables.$primary-neutral-text-color;
            height: auto;
            padding: 0.5rem 1rem;
            align-items: center;
            gap: 0.5rem;
            flex-shrink: 0;
            align-self: stretch;
            white-space: normal;

            &:hover,
            &.selected {
                background: variables.$primary-color;
                color: variables.$text-color-light;
            }

            &:last-child {
                border-bottom: 0px;
            }
        }
    }
}

// Custom Dropdown
.custom-dropdown {
    background: url('../Images/Icons/chevrondown.svg') no-repeat calc(100% - 1rem) center variables.$text-color-light;
    border: 1px solid variables.$primary-border-color;
    width: 100%;
    border-radius: 6px;
    font-size: 0.875rem;

    * {
        font-size: 0.875rem;
    }

    &.show {
        border-radius: 0.75rem 0.75rem 0 0;
    }

    &.selected-color {
        >a {
            color: variables.$secondary-neutral-color;

            &:hover {
                color: variables.$primary-neutral-text-color;
            }
        }
    }

    >a {
        color: variables.$secondary-border-color;
        // color: red;
        padding: 0.6rem 0.75rem;
        width: 100%;
        display: inline-block;



        &.default-selected {
            color: variables.$secondary-border-color;
        }

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        background: variables.$text-color-light;
        left: -1px !important;
        top: 0px !important;
        z-index: 9999;
        width: calc(100% + 2px);
        border: 1px solid variables.$primary-border-color;
        border-radius: 0 0 0.75rem 0.75rem;
        overflow: hidden;
        transform: translate(0px, 2.5rem) !important;
        padding: 0px;
        inset: 0px auto auto 0px !important;

        .dropdown-item {
            background: variables.$text-color-light;
            border-bottom: 1px solid variables.$primary-border-color;
            display: flex;
            color: variables.$primary-neutral-text-color;
            height: 2.5rem;
            padding: 0.5rem 1rem;
            align-items: center;
            gap: 0.5rem;
            flex-shrink: 0;
            align-self: stretch;

            &:hover,
            &.selected {
                background: variables.$primary-color;
                color: variables.$text-color-light;
            }

            &:last-child {
                border-bottom: 0px;
            }
        }
    }
}

// Search Box

.search-input-box {
    margin-right: 0.75rem;
    max-width: 15rem;


    &.search-input-box-xl {
        max-width: 25rem;
    }

    &.search-input-box-large {
        max-width: 20rem;

        .form-control {
            width: 100%;
            max-width: 100%;
        }
    }

    .search-input-box-input {
        padding-left: 2.9rem;
        padding-right: 2.9rem;
        border-radius: 3rem !important;
    }

    .search-input-box {
        padding-left: 2.9rem;
        padding-right: 2.9rem;
        border-radius: 3rem !important;
    }

    .search-input-box-btn {
        background: transparent;
        width: 2.8rem;
        border-right: 0;
        position: absolute;
        left: 2px;
        top: 2px;
        z-index: 6;
        height: 2.2rem;
        border: 0;
        border-radius: 0;

        &.search-input-box-close {
            left: auto;
            right: 0rem;

            .close-btn {
                width: 2.8rem;
            }
        }

        .search {
            background-size: 1rem !important;
        }
    }

}

.container-1 {
    display: flex;
    justify-content: space-between;
}

.column-1 {
    flex: 1;
}

//notes should be in center in order confirmation screens
.notes-confirmation {
    transform: translateY(22%);
}

/* Styles for mobile devices */
@media only screen and (max-width: 767px) {

    .d-none-mobile {
        display: none !important;
    }

    .main-page {
        // display: block !important;
        min-height: 100vh !important;
        overflow-y: auto;
    }

    .main-content {
        padding-top: 2rem;
    }

    .mobile-h1 {
        border-bottom: 1px solid variables.$primary-border-color;
        padding: 1rem 2rem;
        margin-bottom: 0.5rem;
    }

    .mobile-login-btns {
        display: flex;
        flex-wrap: wrap;
        height: 75px;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 0rem;
        overflow: hidden;
    }

    .anonymous-login-main-div {
        position: fixed;
        z-index: 9999;
        top: 0px;
        left: 0px;
        margin: 0px;
        background: variables.$text-color-light;
        height: 100%;
        display: none;
        width: 100%;
        overflow: auto;

        &.active {
            display: block;
        }
    }

    .login-page {
        background-size: auto 100% !important;
        height: 100vh;
        background-repeat: no-repeat;
    }

    .login-block {
        background: variables.$text-color-light;
        color: variables.$primary-neutral-text-color;
        padding: 5rem 2rem;
        padding-top: 10rem;
        border-radius: 0.625rem;
        margin: 0px auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        .login-back-link {
            label {
                font-size: 1rem;
            }
        }
    }

    .search-input-box {
        max-width: inherit;
        max-width: 100%;

        &.search-input-box-xl,
        &.search-input-box-large {
            max-width: inherit;
            max-width: 100%;
        }
    }

    // For Landing Page Onboard Reseller Form
    ._form_303 {
        min-height: 45rem !important;
        width: 100%;

    }

}

hr {
    border-top: 1px solid variables.$primary-border-color;
}

// Custom Scrollbar
/* width */
::-webkit-scrollbar {
    width: 0.35rem;
    height: 0.35rem;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.425rem variables.$primary-border-color;
    border-radius: 0.425rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 0.425rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: variables.$primary-neutral-text-color;
}

/* Styles for tablets */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .login-block {
        margin: 15px auto;
        padding: 30px;
        min-height: 32vh;
        height: auto;
    }

    .main-content {
        padding-top: 2rem;
    }

    .height-dashboard-charts {
        // height: auto !important;
        max-height: 85vh;
        scroll-behavior: hidden;
    }

    .height-dashboard-piechart {
        height: 85vh !important;
    }

    .servcies-dropdown {

        >a {
            width: 80%;
        }
    }

    ._form_303 {
        min-height: 45rem !important;
        width: 100%;

    }
}

/* Styles for tablets Landscapes */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .login-block {
        margin: 15px auto;
        padding: 30px;
        min-height: 53vh;
        height: auto;

        p {
            margin-bottom: 1rem;
        }
    }

    .height-dashboard-charts {
        max-height: 85vh;
        scroll-behavior: hidden;
    }

    .height-dashboard-piechart {
        height: 85vh !important;
    }

    .servcies-dropdown {

        >a {
            width: 90%;
        }
    }
}

/* Styles for screens between 1024px and 1366px */
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .login-block {
        margin: 0.625rem auto;
        padding: 2rem;
        min-height: 75vh;
    }

    .servcies-dropdown {
        >a {
            width: 90%;
        }
    }
}

/* Styles for screens between 1367px and 1600px */
@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .login-block {
        margin: 0.625rem auto;
        padding: 2rem;
        min-height: 60vh;
    }
}



/* Styles for screens wider than 1601px */
@media only screen and (min-width: 1601px) {
    .height-dashboard-charts {
        height: 70vh !important;
        scroll-behavior: hidden;
    }
}


// Table 
.table {
    thead {
        &.sticky-top {
            z-index: 0 !important;
        }

        &.sticky-top {
            th {
                background-color: rgba(250, 250, 250, 1);
                border-bottom: 1px solid #ebebeb;
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;
                word-break: normal !important;
                position: sticky !important;
                top: -1px;

                .billing-term-csp-coterm {
                    color: variables.$secondary-neutral-color;
                    font-size: 0.875rem;
                    font-weight: 500;
                }
            }
        }

        th {
            background: rgba(221, 221, 221, 0.25);
            border-bottom: 1px solid #ebebeb;
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            word-break: normal !important;
        }
    }

    tbody {
        td {
            border-bottom: 1px solid variables.$primary-border-color;
            font-size: 0.875rem;
            line-height: 1.125rem;
            word-break: normal !important;

            * {
                font-size: 0.875rem;
                line-height: 1.125rem;
            }
        }
    }
}

// Details Box
.details-box {
    border-radius: 0.8rem;
    background: variables.$text-color-light;
    padding: 0;
    margin: 1rem 0;
    position: relative;
    box-shadow: 4px 4px 4px 0px rgba(223, 223, 223, 0.25);
}

.table-heading {
    padding-left: 2rem;
    padding-right: 2rem;
}

.subscription-heading-table-header {
    font-size: 1.25rem;
}

.management-console-table {
    position: relative;
}

.plan-status {
    color: variables.$secondary-neutral-color;
    font-size: .75rem !important;
    text-transform: capitalize;
}

.tooltip-inner {
    background: variables.$widget-background-secondary;
    color: variables.$secondary-neutral-color;
    font-size: 0.75rem;
    text-align: left;
    padding: 0.5rem;
}

.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: variables.$widget-background-secondary;
}

.gotoback {
    cursor: pointer;

    * {
        font-size: 1.25rem;
        font-weight: 500;
    }

    .text-normal {
        font-weight: 400 !important;
    }

}

.mobile-padding-search-open-sub {
    min-height: 40px;
}



@media screen and (max-width: 992px) {
    .main-content {
        padding-top: 1rem;
    }
}


// IAAS Usage Report Inner Table
.unbilled-usage-table-inner-table {
    .rdt_Table {
        .rdt_Table {
            .rdt_TableHead {
                .rdt_TableHeadRow {

                    .rdt_TableCol {
                        &:first-child {
                            min-width: 13vw !important;
                        }

                        div {
                            width: 100%;
                            text-align: left;

                            div {
                                width: 100%;
                                text-align: left !important;
                                padding-left: 1rem;
                                padding-right: 1rem;
                            }

                        }
                    }
                }
            }

            .rdt_TableBody {
                .rdt_TableRow {

                    .rdt_TableCell {
                        &:first-child {
                            min-width: 13vw !important;

                            +.rdt_TableCell {
                                text-align: right;

                                >div {
                                    width: 100%;
                                    text-align: right;

                                    >div {
                                        width: 100%;
                                        text-align: right !important;
                                    }
                                }

                                +.rdt_TableCell {
                                    text-align: right;

                                    >div {
                                        width: 100%;
                                        text-align: right;

                                        >div {
                                            width: 100%;
                                            text-align: right !important;
                                        }
                                    }
                                }
                            }
                        }



                        div {
                            width: 100%;
                            text-align: left;

                            div {
                                width: 100%;
                                text-align: left !important;
                                padding-left: 1rem;
                                padding-right: 1rem;
                            }

                        }
                    }
                }
            }
        }
    }

    #cell-Estimated {
        div {
            width: 100%;

            div {
                text-align: right !important;
            }

        }
    }
}

.gws-manage-actions-black {
    color: variables.$text-color;
    font-weight: 400 !important;
    font-size: 0.875rem;

    &.active {
        font-weight: 500 !important;
        border-bottom-color: variables.$primary-color !important;
    }

    &:hover {
        color: variables.$text-color;
        border-bottom-color: variables.$primary-color !important;
    }
}

.addons-core-gws-manage {
    color: variables.$text-color;
    border: 0px solid variables.$background-secondary-color !important;

    &.innerTabs {
        &.active {
            color: variables.$primary-neutral-text-color !important;
            border-radius: 30px;
            background-color: #f2f2f2 !important;
            font-weight: 500 !important;
            border: 2px solid variables.$widget-background-secondary !important;
        }
    }

    &.active {
        color: variables.$primary-color !important;
        border-radius: 30px;
        background-color: variables.$text-color-light !important;
        font-weight: 500 !important;
        border: 2px solid variables.$widget-background-secondary !important;
    }
}

// for dashboard tab buttons
.dashboard-tabs {
    color: variables.$text-color;
    border: 2px solid variables.$text-color-light !important;

    &:hover {
        color: variables.$text-color;
    }

    &.active {
        color: variables.$primary-color !important;
        border-radius: 30px;
        background-color: variables.$text-color-light !important;
        font-weight: 500 !important;
        border: 2px solid #3599DA80 !important;
    }
}

.bulk-mapping-tabs {
    color: variables.$text-color;
    border: 0px solid variables.$background-secondary-color !important;

    &.active {
        color: variables.$primary-color !important;
        border-radius: 30px;
        background-color: variables.$text-color-light !important;
        font-weight: 500 !important;
        border: 2px solid variables.$widget-background-secondary !important;
    }
}

.table-manage-gws {
    margin-bottom: 0;
}

.MuiStepLabel-iconContainer {
    circle {
        color: variables.$text-color-light !important;
        stroke: gray !important;
        /* Set the border color */
        stroke-width: 1 !important;
        transform: scale(0.8, 0.8) translate(10%, 10%) !important;
    }

    .MuiStepIcon-text {
        fill: gray;
        font-size: 0.8rem !important;
    }

    &.Mui-active {
        circle {
            color: variables.$text-color-light !important;
            stroke: variables.$primary-color !important;
            /* Set the border color */
            stroke-width: 1 !important;
        }

        .MuiStepIcon-text {
            fill: variables.$primary-color;
            font-size: 0.8rem !important;
        }
    }

    &.Mui-completed {
        path {
            color: variables.$primary-color !important;
            transform: scale(0.8, 0.8) translate(10%, 10%) !important;
        }
    }
}

// hr Tag in Popup
hr {
    border: 0;
    border-top: 1px solid;
    border-top: 1px solid;
    color: variables.$text-color;
    margin: 1rem 0;
    opacity: .25;
}

// Search Input Group
.search-input-group {
    display: flex;
    align-items: center;
    border: 1px solid variables.$primary-border-color;
    border-radius: 6px;
    overflow: hidden;

    input {
        width: calc(100% - 30px) !important;
        border-radius: 0px !important;
        border: 0px !important;
    }

    .search-icon {
        background: url('../Images/Icons/search.svg') no-repeat;
        width: 30px !important;
        cursor: pointer;
    }

    .clear-search {
        background: url('../Images/Icons/closebtn.svg') no-repeat center;
        width: 30px !important;
        cursor: pointer;
        border: 0px;
    }
}

// Management Console CSS


@media screen and (max-width: 767px) {
    .overflow-hidden-sm {
        overflow: hidden;
    }

    .service-logo {
        width: 100%;
        text-align: center;
        margin: 1.5rem 0 0 0;

        img {
            width: 10rem;
        }
    }

    .mobile-actions-hide {
        display: none;
    }
}

.provision-list-block-outer {
    // max-height: 70vh;
    // overflow-y: auto;
}

.online-services-outer {
    max-height: 70vh;
    overflow-y: auto;
}

.online-services {
    .accordion {
        // margin-bottom: 1rem;

        .accordion-item {
            border: 0px;

            .accordion-header {
                border: 0px;

                button {
                    padding: 0 !important;
                    background: none !important;
                    box-shadow: none !important;
                    font-size: 0.875rem;

                    &:focus {
                        box-shadow: none !important;
                    }
                }
            }

            .accordion-body {
                padding: 0.5rem 0rem 0rem 0rem;
                overflow: auto;

                >p {
                    background-color: variables.$text-color-light !important;
                    padding: 0.3rem 0.5rem;
                    border-radius: 1.5rem;
                    color: variables.$primary-neutral-text-color;
                    width: 98%;
                    margin-bottom: 0.1rem;
                }

                >.active {
                    background-color: variables.$table-row-hover !important;
                    font-weight: 500;
                }
            }
        }
    }
}

.three-dots-icon-disabled {
    color: variables.$primary-border-color !important;
}

.empty-paragraph {
    min-height: 0.6rem;
}

.card-remove-border-prerequisite {
    border: none;
}

//profile reseller prerequisite border error block
.borderErrors {
    border: 1px solid variables.$primary-danger;
}

.sample-image-svg {
    width: 6.25rem;
    height: auto;
}

.back-blue-color {
    color: variables.$primary-color !important;
}

.empty-subscription-detail {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


//integration agreement mapping column color change
.custom-bg-color {
    background-color: #DDDDDD40 !important;
}

//integrations 
.highlight-row td {
    background-color: variables.$widget-background-secondary !important;
}

.tooltip-integrations {
    transform: scale(0.9) translateY(0.1rem);
    z-index: 999999;

    .tooltip-inner {
        max-width: 100%;
        width: 500px;
    }
}


.profile-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

// dashboard
.height-dashboard-charts {
    height: 80vh;
    scroll-behavior: hidden;
}

.height-dashboard-piechart {
    height: 75vh;
}

.MuiChartsAxis-root {
    * {
        font-size: 0.6rem !important;
    }
}

.MuiChartsLegend-series {
    * {
        font-size: 0.75rem !important;
    }
}

.MuiChartsLegend-mark {
    transform: translateY(0.2rem);
    rx: 0.75rem;
    ry: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
}

.MuiChartsLegend-row {
    * {
        align-items: center;
    }

}

.MuiChartsTooltip-root {
    border-radius: 0.5rem !important;
}

.MuiChartsTooltip-table {
    background-color: variables.$primary-neutral-text-color;
    border-radius: 0.5rem;

    thead {
        tr {
            td {
                p {
                    color: variables.$text-color-light !important;
                }

            }
        }
    }
}

.MuiChartsTooltip-labelCell {
    color: variables.$text-color-light !important;
    display: none;
}

.MuiChartsTooltip-valueCell {
    color: variables.$text-color-light !important;
    padding-left: 0.5rem !important;
    padding-top: 0.1rem !important;
    padding-bottom: 0.1rem !important;

    p {
        color: variables.$text-color-light !important;
    }
}

.MuiChartsTooltip-mark {
    border: none !important;
}

.MuiChartsTooltip-markCell {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiChartsTooltip-row {
    color: variables.$text-color-light !important;
}

.list-items {
    padding-left: 0;

    li {
        position: relative;
        margin-bottom: 1rem;
        list-style: none;
        padding-left: 1.5rem;
        line-height: 1.3rem;

        &:before {
            position: absolute;
            left: 0;
            top: 0.3rem;
            content: "";
            width: 0.6rem;
            height: 0.6rem;
            background: #120E0E;
            border-radius: 0.6rem;
        }
    }
}

// Bulk Upload Table Common

.bulk-upload-table {
    div {
        &[role=row] {
            >div[data-column-id="Email"] {
                min-width: 15vw;
            }
        }
    }
}

.gws-plans-dropdown {
    .servcies-dropdown {
        .dropdown-menu {
            transform: translate(0px, 6.25rem) !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .MuiChartsLegend-mark {
        transform: translateY(0.5rem);
        rx: 0.75rem;
        ry: 0.75rem;
        width: 0.75rem;
        height: 0.75rem;
    }

    .bulk-upload-table {
        div[role=row]>div:last-child {
            width: auto !important;
            flex: 0 0 !important;
            padding-left: 1rem;
            padding-right: 1rem;
            min-width: 50vw;
        }
    }
}

//dasboard popup filter dropdown
.dropdown-specialclass {
    .dropdown-menu {
        top: 0 !important;
        min-height: 6.5rem !important;
    }
}

.dropdown-specialclass-fortwo {
    .dropdown-menu {
        top: 0 !important;
        min-height: 4.2rem !important;
    }
}

// Dropdown services for modal configure notification
.dropdown-specialclass-forthree {
    .dropdown-menu {
        top: 0 !important;
        min-height: 8.3rem !important;
    }
}

.dropdown-specialclass-forfour {
    .dropdown-menu {
        top: 0 !important;
        min-height: 12rem !important;
        max-height: 15rem !important;
    }
}

// Services Dropdown
.servcies-dropdown-sm {
    background: url('../Images/Icons/chevrondown.svg') no-repeat calc(100% - 1rem) center variables.$table-row-hover;
    width: 100%;
    border-radius: 3.5rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;

    &.disabled {
        background-color: variables.$primary-border-color;
    }

    &.nav-item {
        .dropdown-toggle::after {
            background: none !important;
        }
    }

    * {
        font-size: 0.875rem;
    }

    &.show {
        border-radius: 0.75rem 0.75rem 0 0;
    }

    >a {

        padding: 0.6rem 1rem;
        width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        background: variables.$text-color-light;
        z-index: 9999;
        min-width: 100%;
        width: 100%;
        border: 1px solid variables.$primary-border-color;
        border-top: 0px;
        border-radius: 0 0 0.75rem 0.75rem;
        overflow: hidden;
        transform: translate(0px, 2rem) !important;
        padding: 0px;
        max-height: 25vh;
        overflow-y: auto;

        .form-control {
            border-radius: 0;
            border-left: 0;
            border-right: 0;

            &:focus {
                border-top: 0px;
            }

        }

        .dropdown-item {
            background: variables.$text-color-light;
            border-bottom: 1px solid variables.$primary-border-color;
            display: flex;
            color: variables.$primary-neutral-text-color;
            height: auto;
            padding: 0.5rem 1rem;
            align-items: center;
            gap: 0.5rem;
            flex-shrink: 0;
            align-self: stretch;
            white-space: normal;

            &:hover,
            &.selected {
                background: variables.$primary-color;
                color: variables.$text-color-light;
            }

            &:last-child {
                border-bottom: 0px;
            }
        }
    }
}

// .button-transparent-bg{
//     background-color: variables.$text-color !important;
// }

.provision-arrow-icon-card {
    transform: scale(1.4) !important;
}

.provision-left-bar-hr {
    color: #b7babd !important;
}

.notification-icon-card {
    transform: scale(1.2) translateY(0.2rem) !important;
}

.notification-page-icon-card {
    transform: scale(1.4) translateY(0.2rem) translateX(0.3rem) !important;
}

.services-dropdown-sm .dropdown-menu {
    max-height: 15vh !important;
}

.h-100 {
    height: 100%;
}

// For Landing Page Onboard Reseller Form
._form_303 {
    height: 35rem;
    width: 100%;

}

.MuiSnackbarContent-root {
    background-color: #f5f5f5 !important;
    transform: translateY(7vh) !important;
}

._form_299 {
    height: 20rem;
}

.form-check-input[type=radio] {
    border: #BCBCBC 1px solid !important;
}